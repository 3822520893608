import swal from "sweetalert2";

const toastedOption = {
  position: "top-right",
  className: "rounded",
  duration: 5000,
};

function capitalize(word) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}

function strippedContent(content, length = 50) {
  let regex = /(<([^>]+)>)/gi;
  return content.replace(regex, "").substring(0, length) + "..";
}

function confirmModal() {
  return swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#83b0e1",
    cancelButtonColor: "#ff4646",
    confirmButtonText: "Yes",
  });
}

export { toastedOption, capitalize, strippedContent, confirmModal };
